import React, { Component } from 'react';
import axios from 'axios';
import { ethers } from 'ethers';
import contractABI from "../../abi/KalisStakingNativeABI.json";
import { FiRefreshCw, FiAlertTriangle, FiCheckSquare, FiDollarSign } from 'react-icons/fi';
import './StakingOne.css';

const BASE_URL = "https://my-json-server.typicode.com/themeland/gameon-json/staking";
const contractAddress = "0x9ae27a046Ce8D83CFB12EbF0ebD68e4303f77c89";

class StakingOne extends Component {
    state = {
        data: {},
        tabData: [],
        tabContent: [],
        features: [],
        totalStakedKalis: "0",
        stakingAmount: "",
        durationIndex: "0",
        withdrawAmount: "",
        selectedStakeIndex: null,
        userStakes: [],
        userAddress: null,
        isLoading: false,
        errorMessage: '',
        successMessage: '',
        kalisPriceInUSD: 0,
        tvlInUSD: 0,
        
    };

    componentDidMount() {
        this.initializeComponent();
    }

    initializeComponent = async () => {
        this.setState({ isLoading: true });
        try {
            await this.registerEthereumEvents();
            await this.loadTotalStakedKalis();
            await this.loadUserStakes();
            await this.fetchKalisPrice();
            await this.fetchStakingData();
        } catch (error) {
            console.error("Error initializing component:", error);
            this.setErrorMessage("Failed to initialize staking data. Please try again.");
        } finally {
            this.setState({ isLoading: false });
        }
    }

    fetchStakingData = async () => {
        try {
            const response = await axios.get(`${BASE_URL}`);
            const updatedTabData = [
                { title: "6 months", tabClass: "nav-link active", tabID: "six-months-tab", tabLink: "#six-months" },
                { title: "1 year", tabClass: "nav-link", tabID: "one-year-tab", tabLink: "#one-year" }
            ];
            const updatedTabContent = [
                { period: "6 months", tabClass: "tab-pane fade show active", tabID: "six-months", lock: "Yes", fee: "15%", status: "Active", apy: "5% APY" },
                { period: "1 year", tabClass: "tab-pane fade", tabID: "one-year", lock: "Yes", fee: "15%", status: "Active", apy: "13% APY" }
            ];
            this.setState({
                data: response.data,
                tabData: updatedTabData,
                tabContent: updatedTabContent,
                features: response.data.features
            });
        } catch (error) {
            console.error("Error fetching staking data:", error);
            this.setErrorMessage("Failed to fetch staking information. Please try again.");
        }
    }

    registerEthereumEvents = async () => {
        if (window.ethereum) {
            try {
                const accounts = await window.ethereum.request({ method: 'eth_accounts' });
                if (accounts.length > 0) {
                    this.setUserAddress(accounts[0]);
                }
                window.ethereum.on('accountsChanged', this.handleAccountsChanged);
            } catch (error) {
                console.error("Error registering Ethereum events:", error);
            }
        } else {
            this.setErrorMessage("Ethereum provider not detected. Please install MetaMask.");
        }
    }

    handleAccountsChanged = (accounts) => {
        if (accounts.length > 0) {
            this.setUserAddress(accounts[0]);
        } else {
            this.setState({ userAddress: null, userStakes: [] });
            this.setErrorMessage("No account connected. Please connect your wallet.");
        }
    }

    setUserAddress = (address) => {
        this.setState({ userAddress: address }, this.loadUserStakes);
    }

    loadTotalStakedKalis = async () => {
        if (!window.ethereum) return;
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(contractAddress, contractABI, provider);
        try {
            const total = await contract.totalStaked();
            const totalStakedKalis = ethers.utils.formatEther(total);
            this.setState({ totalStakedKalis }, this.calculateTotalValueLockedInUSD);
        } catch (error) {
            console.error("Failed to load the total staked Kalis:", error);
            this.setErrorMessage("Failed to load total staked amount. Please try again.");
        }
    }

    fetchKalisPrice = async () => {
        try {
            const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=kalichain&vs_currencies=usd');
            const kalisPriceInUSD = response.data.kalichain.usd;
            this.setState({ kalisPriceInUSD }, this.calculateTotalValueLockedInUSD);
        } catch (error) {
            console.error("Failed to fetch KALIS price:", error);
            this.setErrorMessage("Failed to fetch current KALIS price. Using last known price.");
        }
    }

    calculateTotalValueLockedInUSD = () => {
        const tvlInUSD = parseFloat(this.state.totalStakedKalis) * this.state.kalisPriceInUSD;
        this.setState({ tvlInUSD });
    }

    loadUserStakes = async () => {
        const { userAddress } = this.state;
        if (!window.ethereum || !userAddress) return;
    
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(contractAddress, contractABI, provider);
    
        try {
            const stakesDetails = await contract.getAllStakesDetails(userAddress);
            const formattedStakes = await Promise.all(stakesDetails.map(async (stake, index) => {
                const accumulatedRewards = await this.calculateAccumulatedRewards(index);
                const expectedRewards = await this.calculateExpectedRewards(index, stake);
                return {
                    index,
                    amount: ethers.utils.formatEther(stake.amount),
                    startTime: new Date(stake.startTime.toNumber() * 1000),
                    endTime: new Date(stake.endTime.toNumber() * 1000),
                    durationIndex: stake.durationIndex.toString(),
                    accumulatedRewards,
                    expectedRewards,
                };
            }));
            console.log("Formatted stakes:", formattedStakes); // Ajoutez cette ligne pour le débogage
            this.setState({ userStakes: formattedStakes });
        } catch (error) {
            console.error("Failed to load user stakes:", error);
            this.setErrorMessage("Failed to load your staking information. Please try again.");
        }
    }

    calculateAccumulatedRewards = async (stakeIndex) => {
        if (!window.ethereum || !this.state.userAddress) return "0";

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(contractAddress, contractABI, provider);

        try {
            const reward = await contract.calculateReward(this.state.userAddress, stakeIndex);
            return ethers.utils.formatEther(reward);
        } catch (error) {
            console.error(`Failed to calculate accumulated reward for stake ${stakeIndex}:`, error);
            return "Error";
        }
    }

    calculateExpectedRewards = async (stakeIndex, stake) => {
        if (!window.ethereum || !this.state.userAddress) return "0";
    
        try {
            const stakeAmount = parseFloat(ethers.utils.formatEther(stake.amount));
            const duration = stake.durationIndex.toString() === "0" ? 183 : 365; // 6 months or 1 year
            const apy = stake.durationIndex.toString() === "0" ? 0.05 : 0.13; // 5% for 6 months, 13% for 1 year
            
            // Calculate rewards based on the full duration and APY
            const expectedReward = stakeAmount * apy * (duration / 365);
            
            console.log(`Expected reward for stake ${stakeIndex}: ${expectedReward}`); // Ajoutez cette ligne pour le débogage
            return expectedReward.toFixed(4);
        } catch (error) {
            console.error(`Failed to calculate expected reward for stake ${stakeIndex}:`, error);
            return "Error";
        }
    }

    handleDurationChange = (event) => {
        this.setState({ durationIndex: event.target.value });
    }
    
    stakeTokens = async () => {
        this.setState({ isLoading: true, errorMessage: '', successMessage: '' });
        if (!this.state.stakingAmount || !window.ethereum) {
            this.setErrorMessage("Please enter a valid amount and ensure your wallet is connected.");
            this.setState({ isLoading: false });
            return;
        }
    
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(contractAddress, contractABI, signer);
    
        try {
            const tx = await contract.stake(this.state.durationIndex, { value: ethers.utils.parseEther(this.state.stakingAmount) });
            await tx.wait();
            this.setSuccessMessage("Stake successful!");
            await this.loadTotalStakedKalis();
            await this.loadUserStakes();
        } catch (error) {
            console.error("Stake failed:", error);
            this.setErrorMessage("Staking failed. Please try again.");
        } finally {
            this.setState({ isLoading: false, stakingAmount: '' });
        }
    }
    

    withdrawTokens = async () => {
        this.setState({ isLoading: true, errorMessage: '', successMessage: '' });
        if (this.state.selectedStakeIndex === null || !window.ethereum) {
            this.setErrorMessage("Please select a stake to withdraw and ensure your wallet is connected.");
            this.setState({ isLoading: false });
            return;
        }

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(contractAddress, contractABI, signer);

        try {
            const tx = await contract.unstake(this.state.selectedStakeIndex, false);
            await tx.wait();
            this.setSuccessMessage("Withdrawal successful!");
            await this.loadTotalStakedKalis();
            await this.loadUserStakes();
        } catch (error) {
            console.error("Withdraw failed:", error);
            this.setErrorMessage("Withdrawal failed. Please try again.");
        } finally {
            this.setState({ isLoading: false, selectedStakeIndex: null });
        }
    }

    emergencyUnstakeTokens = async (stakeIndex) => {
        this.setState({ isLoading: true, errorMessage: '', successMessage: '' });
        if (!window.ethereum || !this.state.userAddress) {
            this.setErrorMessage("Ethereum not available or user not connected");
            this.setState({ isLoading: false });
            return;
        }

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(contractAddress, contractABI, signer);

        try {
            const tx = await contract.unstake(stakeIndex, true);
            await tx.wait();
            this.setSuccessMessage("Emergency unstake successful!");
            await this.loadUserStakes();
            await this.loadTotalStakedKalis();
        } catch (error) {
            console.error("Emergency unstake failed:", error);
            this.setErrorMessage("Emergency unstake failed. Please try again.");
        } finally {
            this.setState({ isLoading: false });
        }
    }
    setErrorMessage = (message) => {
        this.setState({ errorMessage: message, successMessage: '' });
        // Optionnel : effacer le message après un certain délai
        setTimeout(() => this.setState({ errorMessage: '' }), 5000);
    }

    async getPendingRewards() {
        if (!window.ethereum || !this.state.userAddress) return "0";
    
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(contractAddress, contractABI, provider);
    
        try {
            const pendingRewards = await contract.pendingRewards(this.state.userAddress);
            return ethers.utils.formatEther(pendingRewards);
        } catch (error) {
            console.error("Failed to get pending rewards:", error);
            return "0";
        }
    }
    
    reinvestRewards = async (stakeIndex) => {
        this.setState({ isLoading: true, errorMessage: '', successMessage: '' });
        if (!window.ethereum || !this.state.userAddress) {
            this.setErrorMessage("Ethereum not available or user not connected");
            this.setState({ isLoading: false });
            return;
        }
    
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(contractAddress, contractABI, signer);
    
        try {
            const tx = await contract.reinvestRewards(stakeIndex);
            await tx.wait();
            this.setSuccessMessage("Rewards reinvested successfully!");
            await this.loadUserStakes();
            await this.loadTotalStakedKalis();
        } catch (error) {
            console.error("Reward reinvestment failed:", error);
            
            let errorMessage = "Failed to reinvest rewards. Please try again.";
            if (error.error && error.error.data && error.error.data.message) {
                errorMessage = error.error.data.message;
            } else if (error.message) {
                errorMessage = error.message;
            }
            
            if (errorMessage.includes("No rewards to reinvest")) {
                errorMessage = "Pas de KALIS à réinvestir";
            }
            
            this.setErrorMessage(errorMessage);
        } finally {
            this.setState({ isLoading: false });
        }
    }
    withdrawStakeRewards = async (stakeIndex) => {
        this.setState({ isLoading: true, errorMessage: '', successMessage: '' });
        if (!window.ethereum || !this.state.userAddress) {
            this.setErrorMessage("Ethereum not available or user not connected");
            this.setState({ isLoading: false });
            return;
        }
    
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(contractAddress, contractABI, signer);
    
        try {
            // Sélectionner d'abord le stake
            this.selectStakeForAction(stakeIndex);
            
            // Vérifier s'il y a des récompenses à retirer
            const stake = this.state.userStakes[stakeIndex];
            const rewards = parseFloat(stake.accumulatedRewards);
            
            if (rewards <= 0) {
                this.setErrorMessage("No rewards available to withdraw for this stake");
                return;
            }
    
            // Effectuer le retrait des récompenses
            const tx = await contract.claimRewards();
            await tx.wait();
            
            this.setSuccessMessage(`Successfully withdrawn ${rewards.toFixed(4)} KALIS rewards!`);
            
            // Rafraîchir les données
            await this.loadUserStakes();
            await this.loadTotalStakedKalis();
        } catch (error) {
            console.error("Failed to withdraw rewards:", error);
            this.setErrorMessage("Failed to withdraw rewards. Please try again.");
        } finally {
            this.setState({ isLoading: false });
        }
    };


    claimRewards = async () => {
        this.setState({ isLoading: true, errorMessage: '', successMessage: '' });
        if (!window.ethereum || !this.state.userAddress) {
            this.setErrorMessage("Ethereum not available or user not connected");
            this.setState({ isLoading: false });
            return;
        }

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(contractAddress, contractABI, signer);

        try {
            const tx = await contract.claimRewards();
            await tx.wait();
            this.setSuccessMessage("Rewards claimed successfully!");
            await this.loadUserStakes();
            await this.loadTotalStakedKalis();
        } catch (error) {
            console.error("Failed to claim rewards:", error);
            this.setErrorMessage("Failed to claim rewards. Please try again.");
        } finally {
            this.setState({ isLoading: false });
        }
    }

    selectStakeForAction = (selectedIndex) => {
        this.setState({ selectedStakeIndex: selectedIndex });
    }

    setErrorMessage = (message) => {
        this.setState({ errorMessage: message, successMessage: '' });
        setTimeout(() => this.setState({ errorMessage: '' }), 5000);
    }

    setSuccessMessage = (message) => {
        this.setState({ successMessage: message, errorMessage: '' });
        setTimeout(() => this.setState({ successMessage: '' }), 5000);
    }

    calculateProgress = (startTime, endTime) => {
        const now = new Date().getTime();
        const start = startTime.getTime();
        const end = endTime.getTime();
        const progress = ((now - start) / (end - start)) * 100;
        return Math.min(Math.max(progress, 0), 100);
    }

    renderStakeCards() {
        return this.state.userStakes.map((stake, index) => {
            console.log(`Rendering stake ${index}:`, stake);
            const hasRewards = parseFloat(stake.accumulatedRewards) > 0;
            
            return (
                <div className="card stake-card mb-4" key={index}>
                    <div className="card-body">
                        <h4 className="card-title mb-3">Stake #{index + 1}</h4>
                        <h5 className="card-subtitle mb-3 text-primary">{stake.amount} KALIS</h5>
                        
                        <div className="stake-details mb-3">
                            <p className="mb-2">Start: {stake.startTime.toLocaleString()}</p>
                            <p className="mb-2">End: {stake.endTime.toLocaleString()}</p>
                            <p className="mb-2 d-flex justify-content-between align-items-center">
                                Accumulated Rewards: 
                                <span className="text-success font-weight-bold">
                                    {this.formatReward(stake.accumulatedRewards)} KALIS
                                </span>
                            </p>
                            <p className="mb-3">Expected Rewards at End: {this.formatReward(stake.expectedRewards)} KALIS</p>
                        </div>
                        
                        <div className="progress mb-3" style={{ height: '10px' }}>
                            <div 
                                className="progress-bar" 
                                role="progressbar" 
                                style={{
                                    width: `${this.calculateProgress(stake.startTime, stake.endTime)}%`,
                                    backgroundColor: '#5d5fef'
                                }} 
                                aria-valuenow={this.calculateProgress(stake.startTime, stake.endTime)}
                                aria-valuemin="0" 
                                aria-valuemax="100"
                            ></div>
                        </div>
                        
                        <div className="stake-actions d-flex flex-wrap gap-2">
                            <button 
                                onClick={() => this.withdrawStakeRewards(index)} 
                                className="btn btn-success"
                                disabled={!hasRewards}
                            >
                                <FiDollarSign /> Withdraw Rewards
                            </button>
                            <button 
                                onClick={() => this.reinvestRewards(index)} 
                                className="btn btn-primary"
                                disabled={!hasRewards}
                            >
                                <FiRefreshCw /> Reinvest
                            </button>
                            <button 
                                onClick={() => this.selectStakeForAction(index)} 
                                className="btn btn-secondary"
                            >
                                <FiCheckSquare /> Select
                            </button>
                            {new Date(stake.endTime) > new Date() && (
                                <button 
                                    onClick={() => this.emergencyUnstakeTokens(index)} 
                                    className="btn btn-danger"
                                >
                                    <FiAlertTriangle /> Emergency Unstake
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            );
        });
    }

formatReward(reward) {
    const rewardNumber = parseFloat(reward);
    if (isNaN(rewardNumber)) {
        console.error("Invalid reward value:", reward);
        return "0.0000";
    }
    return rewardNumber.toFixed(4);
}
    render() {
    const { isLoading, errorMessage, successMessage, totalStakedKalis, tvlInUSD, stakingAmount, withdrawAmount, durationIndex } = this.state;

    return (
        <section className="staking-area">
            <div className="container">
                {isLoading && (
                    <div className="loading-overlay">
                        <div className="loading-spinner">Loading...</div>
                    </div>
                )}
                {errorMessage && (
                    <div className="popup-message error-popup">
                        <div className="popup-content">
                            <h4>Error</h4>
                            <p>{errorMessage}</p>
                            <button onClick={() => this.setState({ errorMessage: '' })}>Close</button>
                        </div>
                    </div>
                )}
                {successMessage && (
                    <div className="popup-message success-popup">
                        <div className="popup-content">
                            <h4>Success</h4>
                            <p>{successMessage}</p>
                            <button onClick={() => this.setState({ successMessage: '' })}>Close</button>
                        </div>
                    </div>
                )}
                    
                    <div className="row mb-4">
                        <div className="col-12">
                            <div className="card total-staked-card">
                                <div className="card-body">
                                    <h3 className="card-title">Total KALIS Staked</h3>
                                    <p className="card-text large-text">{totalStakedKalis} KALIS</p>
                                    <p className="card-text">Total Value Locked: ${tvlInUSD.toFixed(2)} USD</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row mb-4">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="card-title">Stake KALIS</h4>
                                <div className="form-group mb-3">
                                    <label htmlFor="stakingDuration">Staking Duration</label>
                                    <select 
                                        id="stakingDuration"
                                        className="form-control"
                                        value={durationIndex}
                                        onChange={this.handleDurationChange}
                                    >
                                        <option value="0">6 months (5% APY)</option>
                                        <option value="1">1 year (13% APY)</option>
                                    </select>
                                </div>
                                <div className="input-group mb-3">
                                    <input 
                                        type="text" 
                                        className="form-control"
                                        placeholder="Amount to stake" 
                                        value={stakingAmount}
                                        onChange={e => this.setState({ stakingAmount: e.target.value })}
                                    />
                                    <div className="input-group-append">
                                        <button onClick={this.stakeTokens} className="btn btn-primary" disabled={isLoading}>
                                            Stake
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="card-title">Withdraw KALIS</h4>
                                    <div className="input-group mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Amount to withdraw"
                                            value={withdrawAmount}
                                            onChange={e => this.setState({ withdrawAmount: e.target.value })}
                                        />
                                        <div className="input-group-append">
                                            <button onClick={this.withdrawTokens} className="btn btn-secondary" disabled={isLoading}>
                                                Withdraw
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-12">
                            <h3 className="mb-4">Your Stakes</h3>
                            {this.renderStakeCards()}
                        </div>
                    </div>
                    
                    <div className="row mt-4">
                        <div className="col-12 text-center">
                            <button onClick={this.claimRewards} className="btn btn-lg btn-primary" disabled={isLoading}>
                                <FiDollarSign /> Claim All Rewards
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default StakingOne;